import React, { useState } from "react";
import Container from "../components/Container";
import BreadCrumb from "../components/BreadCrumb";
import { ContactFormDatas } from "../helpers/types";
import { push, set, messageRef } from "../helpers/firebase";
import { IoLocationSharp } from "react-icons/io5";

function ContactUs() {
  const [formData, setFormData] = useState<ContactFormDatas>({
    name: "",
    email: "",
    phone: "",
    subject: "",
  });

  const [status, setStatus] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
  });

  const emailRegex = /\S+@\S+\.\S+/;
  const phoneRegex = /^\d{10}$/;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "email") {
      if (!emailRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "A valid email is required.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    } else if (name === "phone") {
      if (!phoneRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Phone number must be exactly 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "",
        }));
      }
    } else if (value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "phone") {
      // Allow only digits and limit to 10 digits
      newValue = newValue.replace(/[^\d]/g, "").slice(0, 10);
    }

    setFormData((formData) => ({
      ...formData,
      [name]: newValue,
    }));

    // Clear error message if the field is valid
    if (name === "phone" && phoneRegex.test(newValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = { name: "", email: "", phone: "", subject: "" };
    let isValid = true;
    // console.log(newErros);

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = "A valid email is required.";
      isValid = false;
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
      isValid = false;
    }

    if (!formData.subject) {
      newErrors.subject = "Subject is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Submit");

    // Validate form fields
    if (!validateForm()) {
      setStatus({
        type: "error",
        message: "Please check all fields and correct any errors.",
      });
      return;
    }

    const { name, email, phone, subject } = formData;

    try {
      const newMessageRef = push(messageRef);
      await set(newMessageRef, {
        name,
        email,
        phone,
        subject,
      });
      setStatus({ type: "success", message: "Form submitted successfully!" });
      setTimeout(() => {
        setStatus(null);
      }, 5000);

      // Clear the form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
      });
      setErrors({ name: "", email: "", phone: "", subject: "" });
    } catch (error) {
      setStatus({
        type: "error",
        message: "There was an error submitting the form. Please try again.",
      });
    }
  };

  return (
    <div className="pb-12">
      <Container>
        <BreadCrumb pageName={"Contact"} />

        <div className="flex-col flex md:flex-row items-start gap-8 md:gap-24 ">
          <div className="left-div bg-gray-100 p-8 rounded-xl shadow-lg w-full md:w-1/2">
            <h1 className="font-bold text-slate-900 text-3xl ">
              Leave Us a Message
            </h1>
            <p className="text-base text-gray-600">
              Have a project in mind? To request a quote contact us directly or
              fill out the form and let us know how we can help.
            </p>
            {status && (
              <div
                className={`text-sm font-semibold ${
                  status.type === "success" ? "text-green-500" : "text-red-500"
                }`}
              >
                {status.message}
              </div>
            )}
            <form
              className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4"
              onSubmit={handleSubmit}
            >
              <div>
                <input
                  type="text"
                  name="name"
                  className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
                  value={formData.name}
                  placeholder="Name"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
                  value={formData.email}
                  placeholder="Email"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="phone"
                  className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
                  value={formData.phone}
                  placeholder="Phone"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="subject"
                  className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
                  value={formData.subject}
                  placeholder="Subject"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.subject && (
                  <p className="text-red-500 text-sm">{errors.subject}</p>
                )}
              </div>
              <button
                type="submit"
                className="text-white bg-primary w-44 hover:bg-yellow-400 hover:text-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="right-div w-full md:w-1/2">
            <h2 className="font-extrabold text-slate-900 text-4xl mb-4">
              Contact Us
            </h2>
            <h4 className="text-yellow-600 text-left text-3xl font-extrabold mb-2 uppercase flex flex-col">
              NikunjStar Info Solutions
              <span className="text-slate-700 text-xl">
                (OPC) Private Limited
              </span>
            </h4>
            <p className="text-left text-gray-500 mb-4">
              NikunjStar-The Most popular Indian Cards Games,play NikunjStar
              <br />
              Gamesonline win real rupeeswith Millions of players around the
              <br />
              world in real-time!
            </p>
            <h5 className="uppercase font-semibold text-slate-700 text-md underline underline-offset-2 mb-4">
              Registered Address:
            </h5>
            <ul className="">
              <li className="flex gap-2">
                <IoLocationSharp className="mt-1" />
                <span>
                  Achrol House colony, Jacob Road, <br />
                  Jaipur, Rajasthan, 302006.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ContactUs;
