import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import RadioButtonNew from "./RadioButtonNew";
import { push, set, txnRef } from "../helpers/firebase";

function ChessGamePrice() {
  const {
    showChessPriceRadio,
    setShowChessPriceRadio,
    setChessTimer,
    chessTimer,
    setShowChessPayment,
    currentUser,
    setUrl,
  } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");

  console.log(currentUser);

  const generatedInvnos = new Set();

  function generateUniqueInvno() {
    let newInvno;
    do {
      newInvno =
        "IOP-TEST-SAJD-" +
        Math.floor(Math.random() * 1e16)
          .toString()
          .padStart(16, "0");
    } while (generatedInvnos.has(newInvno));
    generatedInvnos.add(newInvno);
    return newInvno;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!chessTimer) {
      setErrorMessage("Please select a price and timer.");
      return; // Stop the form submission
    }
    const paymentDetails = {
      status: "pending",
      mId: "SBKYKewQFJA=RkZGRlJYWFFQV0lOTVhFTUE=",
      invno: generateUniqueInvno(),
      fName: currentUser.displayName.split(" ")[0],
      lName: currentUser.displayName.split(" ")[1],
      mNo: currentUser.phoneNumber ? currentUser.phoneNumber : "8888888888",
      currency: "INR",
      amount: getAmountBasedOnTimer(chessTimer), // Define this function to map chessTimer to amount
    };

    try {
      const response = await fetch(
        "https://indiaonlinepay.com/api/iopregisterupiintent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            opStatus: "1",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(paymentDetails),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setShowChessPriceRadio(false);
        setShowChessPayment(true);
        console.log("Payment successful:", data);
        setUrl(data.intent);

        const newTxnRef = push(txnRef);
        await set(newTxnRef, paymentDetails);
      } else {
        console.error("Payment failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making payment request:", error);
    }
  };

  const handleBack = () => {
    setShowChessPriceRadio(false);

    setChessTimer(0); // Reset the selected timer to clear the radio button
    setErrorMessage(""); // Clear any error message
  };

  const handleRadioChange = (value) => {
    setChessTimer(parseInt(value));
    setErrorMessage(""); // Clear error message when a radio button is selected
  };

  const getAmountBasedOnTimer = (timer) => {
    switch (timer) {
      case 1:
        return 5; // 5 rs for 1 hr
      case 2:
        return 20; // 20 rs for 6 hrs
      case 3:
        return 50; // 50 rs for 24 hrs
      case 4:
        return 100; // 100 rs for 30 days
      default:
        return 0;
    }
  };

  return (
    <>
      {showChessPriceRadio && (
        <div className="absolute inset-0 flex items-center justify-center flex-col bg-gray-100 shadow-lg shadow-gray-800 rounded-lg z-50 w-[500px] left-auto right-auto p-8">
          <h4 className="text-primary text-2xl font-Kanit font-medium leading-6 mb-6">
            Chess Game Price
          </h4>
          <form
            className="flex items-center justify-center flex-col"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-2 gap-6 my-4 text-sm">
              <RadioButtonNew
                value="1"
                checked={chessTimer === 1}
                onChange={(value) => handleRadioChange(value)}
                label="1 Hrs"
                PriceValue="5 Rs."
              />
              <RadioButtonNew
                value="2"
                checked={chessTimer === 2}
                onChange={(value) => handleRadioChange(value)}
                label="6 Hrs"
                PriceValue="20 Rs."
              />
              <RadioButtonNew
                value="3"
                checked={chessTimer === 3}
                onChange={(value) => handleRadioChange(value)}
                label="24 Hrs"
                PriceValue="50 Rs."
              />
              <RadioButtonNew
                value="4"
                checked={chessTimer === 4}
                onChange={(value) => handleRadioChange(value)}
                label="30 Days"
                PriceValue="100 Rs."
              />
            </div>

            {errorMessage && (
              <div className="text-red-500 mb-4 text-sm">{errorMessage}</div>
            )}

            <div className="flex items-center justify-center gap-6 mt-4">
              <button
                type="submit"
                className="text-white bg-secondary shadow-md shadow-black hover:shadow-black hover:bg-yellow-400 hover:text-primary focus:ring-4 focus:ring-yellow-400 font-medium rounded-lg text-sm px-7 py-2.5 focus:outline-none"
              >
                Continue to Payment
              </button>
              <button
                type="button"
                onClick={handleBack}
                className="text-white bg-gray-500 shadow-md shadow-black hover:shadow-black hover:bg-gray-700 hover:text-white focus:ring-4 focus:ring-gray-700 font-medium rounded-lg text-sm px-7 py-2.5 focus:outline-none"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ChessGamePrice;
